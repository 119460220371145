import Button from "../../components/control/button";
import HomepageBox from "../../components/homepage/box/box";
import Currency from "../../components/homepage/currency/currency";

import marketplace from "../../assets/images/marketplace.png";
import compas from "../../assets/images/compas.png";
import swap from "../../assets/images/swap.png";
import stake from "../../assets/images/stake.png";
import bridge from "../../assets/images/bridge.png";
import sdk from "../../assets/images/sdk.png";
import rocket from "../../assets/images/rocket.png";
import rocket2 from "../../assets/images/rocket2.png";

import tech from "../../assets/images/tech.png";
import bitcoin from "../../assets/images/bitcoin.png";
import coins from "../../assets/images/coins.png";
import example from "../../assets/images/example.png";
import community from "../../assets/images/community.png";
import hero from "../../assets/images/hero.png";
import TeamMember from "../../components/teamMember/teamMember";

import karen from "../../assets/images/karen.png";
import luis from "../../assets/images/luis.png";
import zach from "../../assets/images/zach.png";
import med from "../../assets/images/med.png";
import martin from "../../assets/images/martin.png";
import rach from "../../assets/images/Rach.png";
import rmz from "../../assets/images/rmz.jpg";
import mike from "../../assets/images/mike.jpg";
import Advisor from "../../components/teamMember/advisor";

export default function Homepage() {
  return (
    <div className="max-w-full overflow-hidden">
      <div className="absolute top-0 z-0 w-full">
        <img src={hero} alt="hero" className="w-full" />
      </div>
      <div className="relative z-[1]">
        <div className="xl:px-32 sm:px-16 px-12">
          <h1 className="font-neotrax text-accent md:text-7xl text-5xl max-w-[700px] mx-auto text-center mb-6 mt-16">
            Welcome to SRC20 Labs
          </h1>
          <p className="max-w-[900px] text-center mx-auto text-light sm:text-2xl text-lg leading-10 font-sofia">
            Explore SRC20 Labs, where innovation meets blockchain to transform
            how we interact with digital assets. Our platform introduces
            intuitive <span className="text-accent">tools and solutions</span>{" "}
            for tokenization and interoperability across blockchain networks,
            making digital assets more accessible to everyone.
          </p>
          <div className="flex items-center sm:gap-8 gap-4 justify-center mt-8 sm:flex-row flex-col">
            <Button
              type="link-fill"
              to="https://explorer.src20labs.io"
              className=" sm:w-auto w-full !text-lg"
            >
              Go To Explorer
            </Button>
            <Button
              type="link"
              className="sm:w-auto w-full"
              to="https://src20labs.gitbook.io/src20-labs/"
            >
              Whitepaper
            </Button>
          </div>
          <p className="text-accent text-center font-semibold mt-12 mb-8 uppercase">
            EXPLORING OUR ECOSYSTEM
          </p>
        </div>
        <div className="flex items-center justify-center gap-12 xl:px-32 sm:px-12 px-0 flex-wrap sm:w-full w-4/5 mx-auto">
          <HomepageBox
            icon={<img src={bitcoin} alt="bitcoin" />}
            title="ABOUT US"
            text={
              <p>
                SRC20 Labs simplifies blockchain, making it accessible for
                everyone. We use the{" "}
                <span className="text-accent">
                  BTC blockchain and the SRC20 standard
                </span>{" "}
                to bring digital assets closer to people.
              </p>
            }
          />
          <HomepageBox
            icon={<img src={rocket} alt="rocket" />}
            title="VISION"
            text={
              <p>
                We aim for a future where blockchain is easy and practical for
                all. SRC20 Labs is{" "}
                <span className="text-accent">crafting solutions</span> that
                simplify digital asset management and use.
              </p>
            }
          />
          <HomepageBox
            icon={<img src={tech} alt="tech" />}
            title="TECHNOLOGY"
            text={
              <p>
                Our core is the SRC20 standard, simplifying digital asset
                management. We focus on{" "}
                <span className="text-accent">easy-to-use</span> tools that make
                blockchain straightforward for all.
              </p>
            }
          />
        </div>
        <div className="mt-32 max-w-full overflow-hidden ">
          <p className="text-center w-4/5 mx-auto text-accent font-semibold mb-10 ">
            EXPLORING OUR ECOSYSTEM
          </p>
          <div className="flex flex-col gap-2 ">
            <div className="flex  items-center justify-center gap-10 w-full mt-8  relative h-16 ">
              <div className="flex flex-row-reverse items-center justify-center gap-10 w-max scrollbox absolute px-5 ">
                <Currency
                  icon={<img src={compas} alt="busd" />}
                  label="Blockchain Explorer"
                />
                <Currency
                  icon={<img src={marketplace} alt="eth" />}
                  label="Marketplace"
                />
                <Currency
                  icon={<img src={swap} alt="mvx" />}
                  label="Token Swap"
                />
                <Currency
                  icon={<img src={stake} alt="rf" />}
                  label="Staking Platform"
                />
                {/* <Currency
                  icon={<img src={rocket2} alt="usdc" />}
                  label="Launchpad"
                /> */}
                <Currency
                  icon={<img src={bridge} alt="vc" />}
                  label="Crosschain Bridge"
                />
                <Currency
                  icon={<img src={sdk} alt="weth" />}
                  label="Software Dev Kit"
                />
              </div>
              <div className="flex flex-row-reverse items-center justify-center gap-10 w-max scrollbox2 absolute px-5 ">
                <Currency
                  icon={<img src={compas} alt="busd" />}
                  label="Blockchain Explorer"
                />
                <Currency
                  icon={<img src={marketplace} alt="eth" />}
                  label="Marketplace"
                />
                <Currency
                  icon={<img src={swap} alt="mvx" />}
                  label="Token Swap"
                />
                <Currency
                  icon={<img src={stake} alt="rf" />}
                  label="Staking Platform"
                />
                {/* <Currency
                  icon={<img src={rocket2} alt="usdc" />}
                  label="Launchpad"
                /> */}
                <Currency
                  icon={<img src={bridge} alt="vc" />}
                  label="Crosschain Bridge"
                />
                <Currency
                  icon={<img src={sdk} alt="weth" />}
                  label="Software Dev Kit"
                />
              </div>
            </div>
            <div className="flex  items-center justify-center gap-10 w-full mt-8  relative h-16 ">
              <div className="flex flex-row-reverse items-center justify-center gap-10 w-max scrollbox3 absolute px-5 ">
                <Currency
                  icon={<img src={compas} alt="busd" />}
                  label="Blockchain Explorer"
                />
                <Currency
                  icon={<img src={marketplace} alt="eth" />}
                  label="Marketplace"
                />
                <Currency
                  icon={<img src={swap} alt="mvx" />}
                  label="Token Swap"
                />
                <Currency
                  icon={<img src={stake} alt="rf" />}
                  label="Staking Platform"
                />
                {/* <Currency
                  icon={<img src={rocket2} alt="usdc" />}
                  label="Launchpad"
                /> */}
                <Currency
                  icon={<img src={bridge} alt="vc" />}
                  label="Crosschain Bridge"
                />
                <Currency
                  icon={<img src={sdk} alt="weth" />}
                  label="Software Dev Kit"
                />
              </div>
              <div className="flex flex-row-reverse items-center justify-center gap-10 w-max scrollbox4 absolute px-5 ">
                <Currency
                  icon={<img src={compas} alt="busd" />}
                  label="Blockchain Explorer"
                />
                <Currency
                  icon={<img src={marketplace} alt="eth" />}
                  label="Marketplace"
                />
                <Currency
                  icon={<img src={swap} alt="mvx" />}
                  label="Token Swap"
                />
                <Currency
                  icon={<img src={stake} alt="rf" />}
                  label="Staking Platform"
                />
                {/* <Currency
                  icon={<img src={rocket2} alt="usdc" />}
                  label="Launchpad"
                /> */}
                <Currency
                  icon={<img src={bridge} alt="vc" />}
                  label="Crosschain Bridge"
                />
                <Currency
                  icon={<img src={sdk} alt="weth" />}
                  label="Software Dev Kit"
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <p className="text-center w-4/5 mx-auto text-accent font-semibold mb-10  mt-32 ">
            OUR TEAM
          </p>
          <div className="teamGrid content-center w-4/5 mx-auto">
            <TeamMember
              name="Karen"
              role="CEO"
              description="8 years of experience in the crypto industry, participated in 30+ different projects on various blockchains as an advisor, consultant and ideologist. Passionate about the transformative potential of this technology."
              image={karen}
            />
            <TeamMember
              name="Luis"
              role="Project Manager"
              description="6 years of experience in the crypto industry, expert project manager and designer, specializing in innovative bitcoin projects over the past year."
              image={luis}
            />
            <TeamMember
              name="Zach"
              role="Blockchain Architect and Lead Developer"
              description="Bringing 8 years of experience in blockchain engineering and 10 years as a software engineer, Zach has been instrumental in developing numerous blockchain applications since the inception of smart contracts. Since early 2022, he has focused on Bitcoin token standards, beginning with ordinals. His current dedication lies in ensuring seamless and user-friendly interaction with SRC20 for both developers and non-technical users."
              image={zach}
            />
            <TeamMember
              name="Med"
              role="Software Architect and Lead Developer"
              description="With a decade of experience in software engineering and architecture, Med has been involved in developing web applications across finance, insurance, and real estate sectors. Proficient in various web stacks, he ensures the delivery of high-quality software with cutting-edge security measures. Med oversees projects from the ideation phase through production and maintenance."
              image={med}
            />
            <TeamMember
              name="Martin"
              role="Frontend Developer"
              description="With 4 years of experience in building responsive and user-friendly interfaces, Martin excels in integrating cutting-edge technologies on the frontend. His extensive experience includes integrating web3 services into various blockchain-based applications. Martin is dedicated to creating seamless user experiences, even within highly complex Blockchain and Bitcoin standards."
              image={martin}
            />
            <TeamMember
              name="Rach"
              role="DevOps Engineer"
              description="Rach is a key asset in managing complex server-side architectures, particularly within Bitcoin infrastructure. With extensive experience in setting up and maintaining complex blockchain protocols for major blockchains, Rach ensures smooth operations for our projects."
              image={rach}
            />
          </div>
        </div>
        <div>
          <p className="text-center w-4/5 mx-auto text-accent font-semibold mb-10  mt-32 ">
            OUR ADVISORS
          </p>
          <div className="teamGrid content-center w-4/5 mx-auto">
            <Advisor
              name="Mike In Space"
              description={`Mike In Space is the creator of the Bitcoin Stamps protocol, which allows for the permanent storage of art and data on the Blockchain. Mike has been involved with NFTs since 2016 and contributed to the pioneering Rare Pepe project on Counterparty. Mike is also a long-time Bitcoiner, social media personality and hosted the satirical Bitcoin-centric talk show "Bitcoin Car Talk" for a number of years.`}
              image={mike}
            />
            <Advisor
              name="RMZ"
              description={`RMZ is a crypto genius when it comes to researching & marketing for top inscriptions-based projects. He does not just writes for publications such as CoinMarketCap, NasDAQ but also serves as key player in listing tokens on different CEX.`}
              image={rmz}
            />
          </div>
        </div>
        <div className=" xl:px-32 sm:px-16 px-8 bg-[url('./assets/images/arch.png')] bg-cover bg-no-repeat bg-[center_-4rem] pt-32 max-w-full overflow-hidden">
          <div className="mt-32 mx-auto flex items-center flex-col relative  ">
            <img src={example} alt="example" className="z-[5]" />
            <img src={coins} alt="coins" className="-translate-y-1/2 z-[5]" />
          </div>
          <div className="pt-14 sm:pb-24 pb-8 relative ">
            <div className="absolute top-1/2 left-1/2 translate-x-1/2 -translate-y-1/2 bg-[url('./assets/images/glow.png')] bg-contain bg-center bg-no-repeat">
              <img
                src={community}
                alt="community"
                className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-none h-auto"
              />
            </div>

            <div className="relative z-[3]">
              <p className="max-w-[900px] text-center mx-auto text-light text-[28px] leading-10 font-sofia">
                Join SRC20 Labs and shape the future of blockchain. Developers,
                investors, and enthusiasts alike, we welcome you. Connect,
                contribute, and help us make blockchain open and decentralized
                for all.
              </p>
              <div className="flex items-center sm:gap-8 gap-4 justify-center mt-8 sm:flex-row flex-col">
                <Button
                  type="link-fill"
                  to="https://t.me/src20labs"
                  className="sm:w-auto w-full"
                >
                  Join Community
                </Button>
                <Button
                  type="link"
                  to="https://src20labs.gitbook.io/src20-labs/"
                  className="sm:w-auto w-full"
                >
                  Learn More
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
