import { ReactElement, ReactNode } from "react";

interface CurrencyProps {
  icon: ReactNode | ReactElement;
  label: string;
}

export default function Currency({ icon, label }: CurrencyProps) {
  return (
    <div className="flex items-center gap-4 bg-box rounded-full px-8 py-2 w-max">
      <div className="flex items-center gap-2 bg-accent p-2 w-12 h-12 rounded-full">
        {icon}
      </div>
      <p className="text-white px-4 py-1 rounded-xl bg-boxDark text-lg">
        {label}
      </p>
    </div>
  );
}
