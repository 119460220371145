import { ReactElement, ReactNode } from "react";

interface HomepageBoxProps {
  icon?: ReactNode | ReactElement;
  title: string;
  text: ReactElement;
}

export default function HomepageBox({ icon, title, text }: HomepageBoxProps) {
  return (
    <div className="max-w-[520px] w-full sm:min-w-[360px] rounded-xl border border-accent xl:px-10 px-8 py-4 flex flex-col items-center   box boshadow ">
      <div className="w-14 h-14 flex flex-col items-center justify-center px-3 py-3 rounded-full bg-accent  boxshadow ">
        {icon}
      </div>
      <div>
        <h3 className="text-accent text-2xl font-semibold text-center mb-6 font-neotrax mt-6">
          {title}
        </h3>
        <p className="text-center text-[24px] text-light font-sofia ">{text}</p>
      </div>
    </div>
  );
}
