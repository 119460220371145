import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import twitter from "../../assets/images/twitter.png";
import telegram from "../../assets/images/telegram.png";

export default function Footer() {
  return (
    <footer className="grid md:grid-cols-3 grid-cols-1 gap-8 items-center xl:px-32 sm:px-16 px-12 py-12 border-t border-accent relative z-20 mt-44">
      <img src={logo} alt="" className="md:mx-0 mx-auto" />
      <div className="flex items-center gap-6 justify-center">
        <Link className="text-white font-semibold" to="/">
          Home
        </Link>

        <Link className="text-white font-semibold" to="/">
          Ecosystem
        </Link>
        <a
          className="text-white font-semibold"
          href="https://src20labs.gitbook.io/src20-labs/"
          target="_blank"
          rel="noreferrer"
        >
          Docs
        </a>
      </div>
      <div className="flex items-center gap-6 md:justify-self-end justify-center">
        <a
          className="text-white font-semibold"
          href="https://twitter.com/src20labs"
          target="_blank"
          rel="noreferrer"
        >
          <img src={twitter} alt="twitter" />
        </a>
        <a
          className="text-white font-semibold"
          href="https://t.me/src20labs"
          target="_blank"
          rel="noreferrer"
        >
          <img src={telegram} alt="telegram" />
        </a>
      </div>
    </footer>
  );
}
