export default function Advisor({
  name,
  description,
  image,
}: {
  name: string;
  description: string;
  image: string;
}) {
  return (
    <div className="lg:max-w-[720px] max-w-[420px] w-full lg:flex-row flex-col flex gap-5 mx-auto mb-6 border-2 border-accent rounded-xl bg-boxDark text-white">
      <img
        src={image}
        alt={name}
        className="h-full max-h-[380px] 2xl:w-48 xl:w-40 lg:w-32 w-full lg:rounded-tl-xl lg:rounded-bl-xl object-cover object-top"
      />
      <div className="font-sofia pt-3 pb-5 pr-6 lg:pl-0 pl-6">
        <h2 className="text-3xl font-semibold mb-2">{name}</h2>
        <p className="text-base">{description}</p>
      </div>
    </div>
  );
}
