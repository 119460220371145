interface ButtonProps {
  type:
    | "primary"
    | "secondary"
    | "link"
    | "link-fill"
    | "submit"
    | "tertiary"
    | "soon";
  onClick?: () => void;
  children?: React.ReactNode;
  to?: string;
  className?: string;
}

const getStylesByType = (type: ButtonProps["type"]) => {
  let baseClass =
    "px-10 py-4 rounded-full border cursor-pointer text-dark font-semibold transition-all duration-200 ease-in-out font-sofia ";
  if (type === "primary" || type === "link-fill") {
    baseClass +=
      "!px-12 from-accent to-accent2 bg-gradient-to-r text-white  border-transparent ";
  } else if (type === "secondary") {
    baseClass += "text-white border-accent hover:bg-accent ";
  } else if (type === "tertiary") {
    baseClass +=
      " !py-2 from-accent to-accent2 bg-gradient-to-r text-white border-transparent";
  } else if (type === "link") {
    baseClass += "text-white border-accent hover:bg-accent ";
  } else if (type === "soon") {
    baseClass += "text-white border-transparent relative !pt-5 !px-12";
  }

  return baseClass;
};

export default function Button({
  type,
  onClick,
  children,
  to,
  className,
  ...other
}: ButtonProps) {
  return (
    <>
      {type === "link" || type === "link-fill" ? (
        <a
          href={to}
          target="_blank"
          rel="noreferrer"
          className={getStylesByType(type)}
        >
          {children}
        </a>
      ) : (
        <button
          onClick={onClick}
          className={`${getStylesByType(type)} ${className} `}
        >
          {children}
          {type === "soon" ? (
            <span className="absolute text-xs -top-0.5 left-1/2 -translate-x-1/2 text-accent px-3 w-max py-1 rounded-md font-semibold z-10 opacity-100 ">
              Coming Soon
            </span>
          ) : null}
        </button>
      )}
    </>
  );
}
