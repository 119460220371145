export default function TeamMember({
  name,
  role,
  description,
  image,
}: {
  name: string;
  role?: string;
  description: string;
  image: string;
}) {
  return (
    <div className="max-w-[720px] w-full lg:flex-row flex-col flex gap-3 mx-auto mb-6 border-2 px-6 pt-3 pb-5 border-accent rounded-xl bg-boxDark text-white">
      <img src={image} alt={name} className="w-24 h-24 rounded-full" />
      <div className="font-sofia">
        <h2 className="text-3xl font-semibold">{name}</h2>
        {role ? (
          <h3 className="mb-3 text-lg font-semibold opacity-50">{role}</h3>
        ) : null}
        <p className="text-base">{description}</p>
      </div>
    </div>
  );
}
