import { Route, Routes } from "react-router";
import Homepage from "./pages/homepage";
import Header from "./components/navigation/header";
import Footer from "./components/navigation/footer";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" index element={<Homepage />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
