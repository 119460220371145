import { Link } from "react-router-dom";
import Button from "../control/button";
import logo from "../../assets/images/logo.png";
import twitter from "../../assets/images/twitter.png";
import telegram from "../../assets/images/telegram.png";
import { ReactComponent as MenuIcon } from "../../assets/images/menu.svg";
import { useState } from "react";

export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div className="bg-background  xl:px-32 sm:px-16 px-6 py-8 flex items-center justify-between relative z-50 ">
      <Link to="/">
        <img src={logo} alt="logo" />
      </Link>
      <div>
        <MenuIcon
          className="lg:hidden cursor-pointer"
          onClick={() => setMenuOpen(!menuOpen)}
        />
        <div
          className={`flex gap-6 lg:relative absolute transition-all ease-in-out top-full left-0 lg:h-auto h-screen bg-background lg:w-auto w-full lg:flex-row flex-col lg:items-center items-start lg:px-0 lg:py-0 sm:px-16 px-12 py-8 ${
            menuOpen ? "translate-x-0" : "lg:translate-x-0 -translate-x-full"
          }`}
        >
          <Link className="text-white font-semibold" to="/">
            Home
          </Link>

          <Link className="text-white font-semibold" to="/">
            Ecosystem
          </Link>
          <a
            className="text-white font-semibold"
            href="https://src20labs.gitbook.io/src20-labs/"
            target="_blank"
            rel="noreferrer"
          >
            Docs
          </a>
          <a
            className="text-white font-semibold"
            href="https://twitter.com/src20labs"
            target="_blank"
            rel="noreferrer"
          >
            <img src={twitter} alt="twitter" />
          </a>
          <a
            className="text-white font-semibold"
            href="https://t.me/src20labs"
            target="_blank"
            rel="noreferrer"
          >
            <img src={telegram} alt="telegram" />
          </a>
          <Button type="link-fill" to="https://explorer.src20labs.io">
            Go To Explorer
          </Button>
        </div>
      </div>
    </div>
  );
}
